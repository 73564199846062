import styled from "styled-components";
import {deviceMax, deviceMin} from "../../../styles/device";
import Colors from "../../constants/Colors";

export const SubHeroAreaTextWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  
    @media ${deviceMax.tablet}{
      width: calc(100% + 48px);
      margin-left: -24px;
      background-color: ${Colors.white};
      padding: 0 24px;
    }
`;

export const SubHeroAreaTextContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 33px;
  
  @media ${deviceMax.tablet}{
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const SubHeroAreaImage = styled.div`
  display: none;

  @media ${deviceMax.tablet} {
    flex: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 60px;
    background-color: ${Colors.white};
    margin-right: 0;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: 0 24px;
  }

  > img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    z-index: 2;

    @media ${deviceMax.tablet} {
      width: 100%;
      height: auto;
      border-radius: 8px;
      margin-top: -55px;
    }
  }
    
`;

export const SubHeroAreaBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 30px;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 1;
  background: linear-gradient(180deg, #D2147D 0%, #75227E 76.56%, #541D98 99.99%, #D2147D 100%);
`;

export const SubHeroAreaTitle = styled.div`
  flex: 0 auto;
  min-width: 290px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 60px;

  @media ${deviceMax.tablet}{
    margin-right: 0;
    min-width: auto;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
  }

  > h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: uppercase;
    color: ${Colors.white};
    margin-top: 0;

    @media ${deviceMax.tablet}{
      font-size: 14px;
      text-align: center;
      color: ${Colors.black};
    }
  }

  > h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0;
    color: ${Colors.white};
    margin-top: 15px;
    
    @media ${deviceMax.tablet}{
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      margin-top: 9px;
      color: ${Colors.black};
    }
  }
`;

export const SubHeroAreaContent = styled.div`
  flex: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  
  > p {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0;
    color: ${Colors.white};
    margin-top: 0;
    margin-bottom: 24px;

    @media ${deviceMax.tablet}{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      width: 100%;
      color: ${Colors.black};
    }
    
    &.fw-bold {
        font-weight: bold;
    }

  }
`;
