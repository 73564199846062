import React from "react";
import {
    SubHeroAreaBg,
    SubHeroAreaContent, SubHeroAreaImage,
    SubHeroAreaTextContainer,
    SubHeroAreaTextWrapper,
    SubHeroAreaTitle
} from './SubHeroAreaTextStyled';
import Image from "next/image";
import GeneralBreadcrumb from "../breadcrumb/GeneralBreadcrumbComp";


const SubHeroAreaText = () => {
    const breadcrumbItems = [
        { label: 'Pembe Panjur'},
        { label: 'İstanbul'},
        { label: 'İstanbul Arkadaşlık Sitesi' },
    ];

    return (
        <SubHeroAreaTextWrapper>
            <GeneralBreadcrumb items={breadcrumbItems} />
            <SubHeroAreaTextContainer>
                <SubHeroAreaImage>
                    <SubHeroAreaBg/>
                    <Image src={'/next/assets/images/landing/subheroarea-bg-mobile.jpg'} alt={'pembe panjur'} loading={'lazy'}
                           width={680}
                           height={630}
                           sizes={'100vw'}
                           style={{width: '100%', height: 'auto'}}/>
                </SubHeroAreaImage>
                <SubHeroAreaTitle>
                    <h3>
                        Onlar Pembe Panjur'da tanıştı
                    </h3>
                    <h2>
                        10 milyon kayıtlı üye,
                        yüksek şans!
                    </h2>
                </SubHeroAreaTitle>
                <SubHeroAreaContent>
                    <p>
                        Başarılı eşleştirme sistemi ile üyelerinin mutlu birliktelik hedefi için çalışan Pembe Panjur,
                        kurulduğu 2010 yılından bugüne yüzlerce başarı hikayesine imza attı. Bugün kayıtlı üyesi 10
                        milyona ulaşan Pembe Panjur, sayısız çifte hayatlarını birleştirme şansı yarattı. Çiftlerimiz
                        hikayelerini paylaştıkça bizler çoğaldık, mutlu olduk.
                    </p>
                    <p>
                        Sitemizde yer alan mutluluk hikayelerinin kahramanlarından biri de neden siz olmayasınız?
                    </p>
                    <p className={'fw-bold'}>
                        Pembe Panjur ile ömürlük sevgiyi nasıl buldular?
                    </p>
                </SubHeroAreaContent>
            </SubHeroAreaTextContainer>
        </SubHeroAreaTextWrapper>
    )
}


export default SubHeroAreaText;
