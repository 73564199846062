import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax, deviceMin} from "../../../styles/device";

export const SubHeroSuccessStoryComp = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto 52px;
  display: flex;
  box-sizing: border-box;
  
  @media ${deviceMax.tablet}{
    width: calc(100% + 48px);
    margin-left: -24px;
    background-color: ${Colors.white};
    padding: 0 24px;
    margin-bottom: 0;
  }

  .mySwiper {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    
    > .swiper-wrapper {
      margin-bottom: 30px;
      box-sizing: border-box;
      
      > .swiper-slide {

        border-radius: 10px;
        box-sizing: border-box;
        border: 0.5px solid #0000001A;
      }
    }
  }

  .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-bullet {
    background-color: #FFFFFF;
    border: 1px solid #000000;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: #000000;
  }
`;


export const SubHeroSuccessStoryWrapper = styled.a`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  background: ${Colors.white};
  border-radius: 10px;
  margin-right: 24px;
  height: 260px;
  max-width: 500px;

  @media ${deviceMax.tablet} {
    flex-direction: column;
    height: 353px;
  }

  &:last-child {
    margin-right: 0;
  }

  > .img {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    @media ${deviceMax.tablet} {
      flex: 2;
      align-items: flex-start;
      border-bottom-left-radius: 0;
      border-top-right-radius: 10px;
    }

    > img {
      width: 100%;
      height: auto;
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
    }
  }

  > .sub-hero-content {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    > .wrapper {
      padding: 30px 20px;
      box-sizing: border-box;

      @media ${deviceMax.tablet} {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      > .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        color: ${Colors.blackColor};
        margin-bottom: 20px;

        @media ${deviceMax.tablet} {
          text-align: center;
          margin-bottom: 7px;
        }
      }

      > .detail {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        color: rgba(0, 0, 0, 0.7);
        overflow: hidden;

        @media ${deviceMax.tablet} {
          text-align: center;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        @media ${deviceMin.tablet} {
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
        }
      }
    }


  }
`;
