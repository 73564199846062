import React from 'react';
import UserReview, {UserReviewProps} from './UserReview';
import * as styled from './UserReviewStyled';

export const userReviews: UserReviewProps[] = [
    {
        quote: '/next/assets/images/reviews/quote-large.png',
        avatar: '/next/assets/images/reviews/avatar2.webp',
        name: 'Kerem Sever',
        comment: 'İyi bir sohbet platformu olduğunu söyleyebilirim. Özellikle ciddi ilişki arayan kullanıcılar için uygun. Profil oluştururken detaylı sorular soruyor ve uyumlu kişilerle eşleşmeyi sağlıyor.',
        rating: 5,
    },
    {
        quote: '/next/assets/images/reviews/quote-large.png',
        avatar: '/next/assets/images/reviews/avatar1.webp',
        name: 'Meral Raylan',
        comment: 'Sadece arkadaşlık değil, ciddi ilişki arayan kişilerle de iletişim kurma şansı sunuyor. Profil uyumluluğu oldukça başarılı. Ortak ilgi alanlarına dayalı eşleşmeler bulmak daha kolay.',
        rating: 5,
    },
    {
        quote: '/next/assets/images/reviews/quote-large.png',
        avatar: '/next/assets/images/reviews/avatar3.webp',
        name: 'Dilek Sonsöz',
        comment: 'Güvenilir bir ortam sunuyor. Profil güvenlik ayarları sayesinde istenmeyen kişilerle iletişim kurmayı filtreleyebiliyorsunuz. Arayüzü kullanıcı dostu.',
        rating: 5,
    },
];

const UserReviewsList: React.FC = () => {
    return (
        <styled.UserReviewsList>
            <styled.UserReviewTitle>
                Uygulamamız hakkında
            </styled.UserReviewTitle>
            <styled.UserReviewSubtitle>
                Üyelerimiz ne diyor?
            </styled.UserReviewSubtitle>
            <styled.UserReviewWrapper>
                {userReviews.map((review, index) => (
                    <UserReview key={index} {...review} />
                ))}
            </styled.UserReviewWrapper>
        </styled.UserReviewsList>
    );
};

export default UserReviewsList;