import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax, deviceMin} from "../../../styles/device";

type IProps = {
    backgroundColor?: string;
}

export const PropertyLinesWrapper = styled.div`
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  background: ${(props: IProps) => props.backgroundColor ? props.backgroundColor : Colors.white};
  border-radius: 6px;

  @media ${deviceMax.tablet} {
    padding: 70px 0 30px;
    background: unset;
  }
  
  &.about-us-wrapper {
    padding-top: 23px;
    padding-bottom: 1px;
    
    > .property-lines-container {
      > .property-lines-row {
        border: none;
        box-shadow: unset;
        background: unset;
        border-radius: unset;
      }
    }
  }

  > .about-us-title {
    font-weight: 800;
    font-size: 18px;
    line-height: 55px;
    letter-spacing: 0;
    text-transform: uppercase;
    color: ${Colors.brandColor};
    margin-left: 56px;
    padding-top: 23px;
    box-sizing: border-box;
    margin-top: 0;

    @media ${deviceMax.tablet} {
      margin: 0 auto 21px;
      line-height: 30px;
      letter-spacing: 0;
      color: ${Colors.black};
      padding-top: 0;
    }
  }

  > .property-lines-container {
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    margin: 0 auto 18px;
    border-radius: 10px;
    //padding: 0 89px 5px 15px;

    &:nth-child(2) {
      padding-top: 20px;
    }

    &.basin-container {
      border: none;
      border-radius: unset;
      max-width: 1100px;
      margin-bottom: 0;
      padding: 35px 45px 31px 54px;
      box-shadow: unset;

      > .property-lines-row {
        border: none;
        border-bottom: 1px solid ${Colors.softGreyColor};
        padding-bottom: 30px;
        background: unset;
        box-shadow: unset;
        border-radius: unset;
      }

      &:last-child {
        padding-bottom: 35px;

        > .property-lines-row {
          border-bottom: none;
          margin-bottom: -30px;
        }
      }

      @media ${deviceMax.tablet} {
        padding: 0 24px 20px;
        margin-left: -44px;
        margin-right: -44px;

        &:first-child {
          padding-top: 0;
        }
      }
    }

    @media ${deviceMax.tablet} {
      flex-direction: column;
      padding: 6px 20px 30px;
      align-items: center;
      justify-content: center;
      background: ${Colors.white};
      border: 0.5px solid #0000001A;
      box-shadow: 0px 40px 110px 0px #0000000D;

      &:last-child {
        margin-bottom: 0;
      }
    }

    > .property-lines-row {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      border: 0.5px solid #0000001A;
      box-shadow: 0px 40px 110px 0px #0000000D;
      border-radius: 10px;

      background: ${Colors.white};

      @media ${deviceMax.tablet} {
        flex-direction: column;
        align-items: center;
        justify-content: center;
          border: none;
            box-shadow: unset;
            background: unset;
            border-radius: unset;
        
        &:last-child {
          margin-bottom: 0;
        }
      }

      > .property-lines-wrapper {

        &:first-child {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:last-child {
          flex: 4;

          @media ${deviceMin.tablet} {
            padding-top: 36px;
            padding-bottom: 36px;
            padding-right: 74px;
          }
        }

        &.basin-wrapper {
          margin-left: 28px;

          @media ${deviceMax.tablet} {
            margin-left: 0;
          }

          @media ${deviceMin.tablet} {
            padding-top: 0;
          }
        }

        > .property-lines-icon {
          position: relative;
          width: 100%;
          height: 100%;

          @media ${deviceMax.tablet} {
            margin-top: 30px;
            margin-bottom: 20px;
          }

          &__about_us {
            width: 70%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding-top: 30px;
            
            @media ${deviceMax.tablet} {
              width: 100%;
              padding-top: 0;
            }
          }

          > .image {
            width: 100%;
            height: auto;

            &__basin {
              max-width: 106px;
            }
          }
        }

        > h2 {
          font-family: 'Manrope', sans-serif;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 0;
          color: ${Colors.blackColor};
          margin-bottom: 28px;
          margin-top: 0;

          @media ${deviceMax.tablet} {
            text-align: center;
            width: 100%;
            font-size: 16px;
            font-weight: 800;
          }
        }

        > .basin {

          &_date {
            font-family: 'Manrope', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 0;
            color: ${Colors.purpleColor};
            margin-bottom: 20px;

            @media ${deviceMax.tablet} {
              margin: 0 auto 20px;
            }
          }

          &_text {
            font-family: 'Manrope', sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0;
            color: ${Colors.blackColor};
            margin-bottom: 20px;

            @media ${deviceMax.tablet} {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }

          &_link {
            font-family: 'Manrope', sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0;
            color: ${Colors.blackColor};
            text-decoration: underline;
            transition: all .3s ease;

            &:hover {
              color: ${Colors.brandColor};
            }

            @media ${deviceMax.tablet} {
              display: inline-block;
              width: 100%;
              text-align: center;
              font-weight: 800;
              font-size: 14px;
            }
          }
        }

        > p {
          font-family: 'Manrope', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0;
          color: ${Colors.softBlackColor};
          margin: 0;
          white-space: pre-line;

          @media ${deviceMax.tablet} {
            text-align: center;
          }
        }
      }
    }
  }
`;