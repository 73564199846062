import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {
    SubHeroSuccessStoryComp,
    SubHeroSuccessStoryWrapper
} from "./SubHeroSuccessStoryStyled";
import { Autoplay, Pagination } from 'swiper/modules';
import axios from "axios";
import Image from "next/image";

import data from '../../constants/data/BasariHikayeleriLandingData.json';


const SubHeroSuccessStory = () => {
    const [successStoriesItem, setSuccessStoriesItem] = useState([]);

    useEffect(() => {
        // const fetchSuccessStories = async () => {
        //     const {data} = await axios.get(`${process.env.NEXT_PUBLIC_AIRTABLE_SUCCESS_STORY_PAGES_URL}`, {
        //         headers: {
        //             Authorization: `Bearer ${process.env.NEXT_PUBLIC_AIRTABLE_TOKEN_SUCCESS_STORY_PAGES}`,
        //             'Content-Type': 'application/json'
        //         }
        //     });
        //     setSuccessStoriesItem(data.records);
        // }
        // fetchSuccessStories().then();

        setSuccessStoriesItem(data.BasariHikayeleriLandingData as any);
    }, []);
    return (
        <SubHeroSuccessStoryComp>
            <Swiper
                spaceBetween={16}
                slidesPerView={1}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 16,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                    },
                    1441: {
                        slidesPerView: 4,
                        spaceBetween: 16,
                    },
                }}
                pagination={{clickable: true}}
                lazy = {'true'}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
            >
                {successStoriesItem.map((item: any, index: number) => {
                    return (
                        // Object.keys(item.fields).length > 0 ?
                        Object.keys(item).length > 0 ?
                            <SwiperSlide key={index}>
                                {/*<SubHeroSuccessStoryWrapper href={`/basari-hikayeleri/${item.fields.page_url_name}`}>*/}
                                <SubHeroSuccessStoryWrapper href={`/basari-hikayeleri/${item.page_url_name}`}>
                                    <div className="img">
                                        <Image loading={'lazy'} width={item.image.width}
                                               height={item.image.height}
                                               sizes="100vw"
                                               // style={{ width: '100%', height: 'auto' }} src={item.fields.image} alt="Pembe Panjur"/>
                                               style={{ width: '100%', height: 'auto' }} src={item.image.link} alt="Pembe Panjur"/>
                                    </div>
                                    <div className="sub-hero-content">
                                        <div className="wrapper">
                                            <div className="title">
                                                {/*{item.fields.page_title}*/}
                                                {item.page_title}
                                            </div>
                                            <div className="detail">
                                                {/*{item.fields.content}*/}
                                                {item.content}
                                            </div>
                                        </div>

                                    </div>
                                </SubHeroSuccessStoryWrapper>
                            </SwiperSlide>
                            : null
                    )
                })}
            </Swiper>
        </SubHeroSuccessStoryComp>
    )
}

export default SubHeroSuccessStory;
