import React from "react";
import Link from "next/link";
import PropertyLinesTopSection from "./PropertyLinesTopSection";
import {PropertyLinesWrapper} from "./PropertyLinesStyled";
import Image from "next/image";
import {NavigationURLs} from "../../functions/NavigationFunctions";
import GeneralBreadcrumb from "../breadcrumb/GeneralBreadcrumbComp";

type IProps = {
    layoutPage?: string,
    propertyData: any,
    backgroundColor?: string
};

const PropertyLines = (props: IProps) => {
    const breadcrumbItemsHakkinda = [
        { label: 'Pembe Panjur', link: NavigationURLs.landing },
        { label: 'Hakkında'}
    ];

    return (
        <PropertyLinesWrapper backgroundColor={props.backgroundColor} className={props.layoutPage === 'hakkinda' ? "about-us-wrapper" : undefined}>
            {props.layoutPage === 'basin' ? (
                <PropertyLinesTopSection/>
            ) : null}
            {props.layoutPage === 'hakkinda' ? (
                <React.Fragment>
                    <GeneralBreadcrumb className={'about-us-title noColor'} items={breadcrumbItemsHakkinda} colorProps={false} margin={false}/>
                    <h2 id={'about-us-title'} className={'about-us-title'}>Nasıl çalışır</h2>
                </React.Fragment>
            ) : null}
            {props.propertyData?.map((data: any) => (
                props.layoutPage === 'basin' ? (
                    Object.keys(data.fields).length > 0 ? (
                        <div key={Math.random()}
                             className={'property-lines-container basin-container'}>
                            <div className="property-lines-row">
                                <div className="property-lines-wrapper">
                                    <div
                                        className={'property-lines-icon'}>
                                        <Image loading={'lazy'} width={106}
                                               height={51}
                                               sizes={'100vw'}
                                               style={{width: '100%', height: 'auto'}}
                                               src={`${data.fields.image}`}
                                               alt={'Pembe Panjur'} title={'Pembe Panjur'}
                                               className={'image__basin'}/>
                                    </div>
                                </div>
                                <div
                                    className={'property-lines-wrapper basin-wrapper'}>
                                    <h2 style={{marginBottom: 5}}>
                                        {data.fields.title}
                                    </h2>

                                    <p className={'basin_date'}>
                                        {data.fields.date}
                                    </p>

                                    <p className={'basin_text'}>
                                        {data.fields.content}
                                    </p>
                                    <Link href={`${data.fields.link}`} className={'basin_link'} target={'_blank'}
                                          rel={'noreferrer nofollow'}>
                                        Tümünü oku
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ) : undefined
                ) : (
                    <div key={Math.random()}
                         className={"property-lines-container"}>
                        <div className="property-lines-row">
                            <div className="property-lines-wrapper">
                                <div
                                    className={'property-lines-icon property-lines-icon__about_us'}>
                                    <Image loading={'lazy'}
                                           width={`${data.img.width}`}
                                           height={`${data.img.height}`}
                                           sizes={'100vw'}
                                           style={{width: '100%', height: 'auto', objectFit: 'none'}}
                                           src={`${data.img.link}`}
                                           alt={'Pembe Panjur'} title={'Pembe Panjur'}
                                           className={'image'}/>
                                </div>
                            </div>
                            <div
                                className={'property-lines-wrapper'}>
                                <h2 style={props.layoutPage === 'hakkinda' ? {marginBottom: 0} : undefined}>
                                    {data.title}
                                </h2>
                                <p>
                                    {data.content}
                                </p>
                            </div>
                        </div>
                    </div>
                )

            ))}
        </PropertyLinesWrapper>
    )
}

export default PropertyLines;
