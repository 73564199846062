import React from 'react';
import * as styled from './UserReviewStyled';
import Image from 'next/image';

export interface UserReviewProps {
    quote: string;
    avatar: string;
    name: string;
    comment: string;
    rating: number;
}

const UserReview: React.FC<UserReviewProps> = ({quote, avatar, name, comment, rating}) => {
    return (
        <styled.UserReview>
            <styled.UserIcon>
                <Image loading={'lazy'} width={76}
                       height={80}
                       sizes={'100vw'}
                       style={{width: '100%', height: 'auto'}}
                       src={quote}
                       alt={`Pembe Panjur ${name} ikon`} title={`Pembe Panjur ${name} ikon`}
                />
            </styled.UserIcon>
            <styled.UserComment>
                <p>{comment}</p>
            </styled.UserComment>
            <styled.UserRating>
                <styled.UserRatingWrapper>
                    {Array.from({length: rating}, (_, index) => (
                        <Image key={index}
                               loading={'lazy'} width={70}
                               height={74}
                               sizes={'100vw'}
                               style={{width: '100%', height: 'auto'}}
                               src={'/next/assets/images/reviews/star-icon.png'}
                               alt={`${name} degerlendirme yildizi`} title={`Pembe Panjur ${name} degerlendirme yildizi`}
                        />
                    ))}
                </styled.UserRatingWrapper>
            </styled.UserRating>
            <styled.UserProfile>
                <Image key={Math.random()}
                       loading={'lazy'} width={117}
                       height={120}
                       sizes={'100vw'}
                       style={{width: '100%', height: 'auto'}}
                       src={avatar}
                       alt={`${name} profili resmi`} title={`Pembe Panjur ${name} profili resmi`}
                />
                <p>{name}</p>
            </styled.UserProfile>
        </styled.UserReview>
    );
};

export default UserReview;
