import styled from "styled-components";
import {deviceMax, deviceMin} from "../../../styles/device";
import Colors from "../../constants/Colors";

export const BlogPreviewWrapper = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
  padding: 58px 24px 68px;
  box-sizing: border-box;

  @media ${deviceMax.tablet} {
    padding: 27px 24px 30px;
  }

  > .pp-blog-container {
    position: relative;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0 auto;

    @media ${deviceMax.tablet} {
      flex-direction: column;
    }

    @media ${deviceMin.laptopL} {
      max-width: 1200px;
      margin: 0 auto;
    }

    > .pp-blog-wrapper {
      flex: 1;
      box-sizing: border-box;

      &:last-child {
        padding-left: 130px;

        @media ${deviceMax.laptop} {
          padding-left: 0;
        }

        @media (min-width: 768px) and ${deviceMax.laptop} {
          padding-left: 70px;
        }
      }

      > .pp-blog-img {
        position: relative;
        width: 100%;
        height: 100%;

        @media ${deviceMax.tablet} {
          height: 362px;
          margin-bottom: 40px;
        }

        > img {
          border-radius: 12px;
        }
      }

      > .pp-blog-title {
        font-family: 'Manrope', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        text-transform: uppercase;
        color: ${Colors.brandColor};
        margin-bottom: 25px;

        @media ${deviceMax.tablet} {
          font-size: 14px;
          text-align: center;
        }
      }

      > .pp-blog-sub-title {
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        font-size: 35px;
        line-height: 50px;
        letter-spacing: 0;
        color: ${Colors.blackColor};
        margin-bottom: 50px;

        @media ${deviceMax.tablet} {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          margin-bottom: 38px;
        }
      }

      > .pp-blog-article {
        position: relative;
        display: flex;
        flex-direction: row;
        margin-bottom: 25px;
        transition: all .5s ease;

        @media ${deviceMax.tablet} {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 30px;
        }

        &:hover {
          > .article {
            > .article-title {
              color: ${Colors.brandColor};
            }

            > .article-content {
              color: ${Colors.brandColor};
            }
          }

        }

        > .date {
          width: 72px;
          height: 72px;
          position: relative;
          font-family: 'Manrope', sans-serif;
          font-weight: 700;
          font-size: 32px;
          letter-spacing: 0;
          color: ${Colors.brandColor};
          background: ${Colors.pinkColor};
          border-radius: 50%;
          padding: 10px 19px;
          text-align: center;
          margin-right: 16px;

          @media ${deviceMax.tablet} {
            margin-bottom: 30px;
            margin-right: 0;
          }

          > p {
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0;
            color: ${Colors.brandColor};
            margin: 0 auto;
          }
        }

        > .article {
          position: relative;
          display: flex;
          flex-direction: column;

          @media ${deviceMax.tablet} {
            align-items: center;
            justify-content: center;
          }

          > .article-title {
            font-family: 'Manrope', sans-serif;
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0;
            color: #000000;

            @media ${deviceMax.tablet} {
              text-align: center;
            }
          }

          > .article-content {
            font-family: 'Manrope', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0;
            color: #000000;
            overflow: hidden;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 3;
            -webkit-box-orient: vertical;

            @media ${deviceMax.tablet} {
              text-align: center;
            }
            
            > p {
              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }

      > a.all-article-read-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${Colors.blackColor};
        padding: 13px;
        border-radius: 8px;
        font-family: 'Manrope', sans-serif;
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0;
        text-transform: uppercase;
        color: #000000;
        transition: all .5s ease;
        margin-top: 25px;

        &:hover {
          border-color: ${Colors.brandColor};
          color: ${Colors.brandColor};
          transform: scale(1.05);
        }

        @media ${deviceMax.tablet} {
          border-radius: 36px;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          border: 1px solid ${Colors.blackColor};
        }
      }
    }
  }
`;