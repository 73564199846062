import React, {useEffect, useState} from "react";
import Image from "next/image";
import Link from 'next/link';
import {BlogPreviewWrapper} from "./BlogPreviewStyled";
import axios from "axios";

import data from '../../constants/data/BlogLandingPageData.json';

const BlogPreview = () => {

    const [blogItem, setBlogItemItem] = useState([]);

    useEffect(() => {
    //     const fetchBlogs = async () => {
    //         const {data} = await axios.get(`${process.env.NEXT_PUBLIC_WORDPRESS_BLOG_URL}`);
    //         setBlogItemItem(data);
    //     }
    //     fetchBlogs().then();

        setBlogItemItem(data.BlogLandingData as any);
    }, []);


    return (
        <React.Fragment>
            <BlogPreviewWrapper>
                <div className="pp-blog-container">
                    <div className="pp-blog-wrapper">
                        <div className={'pp-blog-img'}>
                            <Image src={'/next/assets/images/blog-landing-image-tiny.jpeg'} layout={'fill'} objectFit={'cover'}
                                   alt={'Pembe Panjur Blog'} title={'Pembe Panjur Blog'}/>
                        </div>
                    </div>
                    <div className="pp-blog-wrapper">
                        <div className="pp-blog-title">
                            Pembe Panjur Blog
                        </div>
                        <div className="pp-blog-sub-title">
                            Aşk ve ilişkiler hakkında öğrenmek istediğiniz her şey…
                        </div>
                        {blogItem.slice(0, 3).map((data: any) =>
                            <React.Fragment key={Math.random()}>
                                <Link href={data['link']} className="pp-blog-article">
                                    <div className="date">
                                        {/*{moment(data['modified']).format('DD')}*/}
                                        {data.dateDay}
                                        <p>
                                            {/*{localeData.months(moment(data['modified']), ('MMM'))}*/}
                                            {data.dateMonth}
                                        </p>
                                    </div>
                                    <div className="article">
                                        <div className="article-title"
                                             // dangerouslySetInnerHTML={{__html: data['title']['rendered']}}
                                             dangerouslySetInnerHTML={{__html: data['title']}}
                                        >
                                        </div>
                                        <div className="article-content"
                                             // dangerouslySetInnerHTML={{__html: data['content']['rendered']}}
                                             dangerouslySetInnerHTML={{__html: data['content']}}
                                        >
                                        </div>
                                    </div>
                                </Link>
                            </React.Fragment>
                        )}
                        <Link href={'https://www.pembepanjur.com/blog/'} className={'all-article-read-button'}>
                            Tüm makaleleri oku
                        </Link>

                    </div>
                </div>
            </BlogPreviewWrapper>
        </React.Fragment>
    )
}

export default BlogPreview;
