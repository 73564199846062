import React from "react";
import styled from "styled-components";
import {deviceMax} from "../../../styles/device";
import Colors from "../../constants/Colors";
import Image from "next/image";
import {NavigationURLs} from "../../functions/NavigationFunctions";
import GeneralBreadcrumb from "../breadcrumb/GeneralBreadcrumbComp";

const PropertyLinesTopSection = () => {
    const breadcrumbItemsBasin = [
        { label: 'Pembe Panjur', link: NavigationURLs.landing },
        { label: 'Basında Biz'}
    ];

    return (
        <PropertyLinesTopSectionWrapper>
            <div className="property-lines-top-section">
                <GeneralBreadcrumb items={breadcrumbItemsBasin} colorProps={false} margin={false}/>
                <div className="property-lines-top-section-text">
                    <h1>BASINDA BİZ</h1>
                </div>
                <div className="property-lines-top-section-image">
                    <Image loading={'lazy'} width={188}
                           height={250}
                           sizes={'100vw'}
                           style={{width: '100%', height: 'auto'}}
                           src={`/next/assets/images/basin/basin-icon.png`}
                           alt={'Pembe Panjur Basın'} title={'Pembe Panjur Basın'}
                    />
                    <h2>
                        Pembe Panjur kurulduğu 2010 yılından bugüne medya organlarında başarılarıyla adından söz
                        ettiriyor. Basında yer alan tüm haberlere aşağıdaki başlıklardan ulaşabilirsiniz.
                    </h2>
                </div>
            </div>
        </PropertyLinesTopSectionWrapper>
    )
}

const PropertyLinesTopSectionWrapper = styled.div`
  position: relative;
  padding-top: 23px;

  > .property-lines-top-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
    padding: 0 45px 0 54px;
    max-width: 1200px;

    @media ${deviceMax.tablet} {
      padding: 0;
      margin: 0 -20px 30px;
    }

    > .property-lines-top-section-text {
      font-weight: 800;
      font-size: 18px;
      line-height: 55px;
      letter-spacing: 0;
      text-transform: uppercase;
      color: ${Colors.brandColor};
      width: 100%;

      > h1 {
        margin-top: 0;

        @media ${deviceMax.tablet} {
          text-align: center;
          margin: 0 auto;
          line-height: 30px;
          color: ${Colors.blackColor};
        }
      }

    }

    > .property-lines-top-section-image {
      position: relative;
      display: flex;
      flex-direction: row;
      padding-bottom: 44px;
      border-bottom: 1px solid ${Colors.black};

      @media ${deviceMax.tablet} {
        flex-direction: column;
        border-bottom: unset;
        align-items: center;
        margin-top: 25px;
        padding-bottom: 0;
      }

      > img {
        width: 100%;
        height: 125px;
        max-width: 94px;

        @media ${deviceMax.tablet} {
          max-width: 81px;
          height: 108px;
        }
      }

      > h2 {
        margin-top: 0;
        margin-left: 58px;
        margin-bottom: 0;
        font-family: 'Manrope', sans-serif;
        font-weight: 800;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0;
        color: ${Colors.blackColor};

        @media ${deviceMax.tablet} {
          text-align: center;
          margin-left: 0;
          margin-top: 25px;
          font-size: 16px;
          line-height: 24px;
        }

      }
    }

  }
`;

export default PropertyLinesTopSection;
