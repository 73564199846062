import React from "react";
import Colors from "../../constants/Colors";
import ButtonComp from "../buttons/buttonComp/buttonComp";
import styled from "styled-components";
import {NavigationURLs} from "../../functions/NavigationFunctions";

const HowIsYourStory = () => {
    return (
        <HowIsYourStoryWrapper>
            <ButtonComp backgroundColor={Colors.white} borderColors={Colors.borderPurpleColor} visible={true} fullWidth={false} textColor={true} career={true}
                        uppercase={true} url={NavigationURLs.successStorySend}>
                {'Sizin hikayeniz nasıl?'}
            </ButtonComp>
            <ButtonComp backgroundColor={Colors.white} borderColors={Colors.brandColor} visible={true} fullWidth={false} textColor={true} career={true}
                        uppercase={true} url={NavigationURLs.successStoryMulti} className={'brand-text-clr'}>
                {'hikayelerimizi oku'}
            </ButtonComp>
        </HowIsYourStoryWrapper>
    );
};

const HowIsYourStoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
    width: calc(100% + 48px);
    margin-left: -24px;
    background-color: ${Colors.white};
    padding: 30px 24px 0;
  }

  > div {
    margin-bottom: 25px;
    max-width: 503px;

    @media (max-width: 767px) {
      display: flex;
      width: 100%;
    }

    > a {
      > div {
        > div {
          font-weight: 800;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0;
          text-transform: uppercase;
          color: ${Colors.borderPurpleColor};
          
          &.brand-text-clr {
            color: ${Colors.brandColor};
      }
    }
  }

`;

export default HowIsYourStory;
