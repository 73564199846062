import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax, deviceMin} from "../../../styles/device";

export const UserReviewsList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0 54px;
  margin-right: 20px;
  margin-left: 20px;
`;

export const UserReview = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 60px;
  background-color: #ffffff;
  border: 1px solid #00000026;
  
    @media ${deviceMin.tablet} and ${deviceMax.laptop} {
        padding: 40px 30px;
    }

`;

export const UserReviewWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  
`;

export const UserReviewTitle = styled.h2`
  font-family: 'Manrope', sans-serif;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: ${Colors.brandColor};
  margin-top: 0;
  text-transform: uppercase;

`;

export const UserReviewSubtitle = styled.h3`
  font-family: 'Manrope', sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0.011em;
  text-align: center;
  color: ${Colors.blackColor};
  margin-top: 0;
  margin-bottom: 30px;
`;

export const UserIcon = styled.div`
  margin: 0 auto 34px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${deviceMin.tablet} and ${deviceMax.laptop} {
    width: 30px;
    height: 30px;
    margin-bottom: 24px;
  }
  
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UserComment = styled.div`
  margin-bottom: 24px;

  > p {
    margin: 0;
    font-family: 'Manrope', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0;
    text-align: center;
    color: #00000080;
    
    @media ${deviceMin.tablet} and ${deviceMax.laptop} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const UserRating = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;
`;

export const UserRatingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const UserProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;

  > img {
    display: flex;
    width: 100%;
    max-width: 58px;
    height: 60px;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 50%;
  }

  > p {
    display: block;
    font-family: 'Manrope', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0;
    text-align: center;
    box-sizing: border-box;
  }
`;